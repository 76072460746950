<script lang="ts" setup> 
    
    const props = defineProps({ 
        value: String,
        classes: String
    })

    const { value, classes } = props;

</script>

<template>
    <h2 :class="classes" v-html="value" />
</template>

<style lang="scss">

    .d1, .d2, .d3, .h1, .h2 ,.h3, .h4, .h5, .h6 {

        @apply font-hass-display font-medium text-white-50 dark:text-elephant-950;

        em {
            @apply text-lima-600 not-italic
        }

    }
    .d1 {
        @apply text-d1_mobile md:text-d1_tablet lg:text-d1_desktop
    }
    .d2 {
        @apply text-d2_mobile md:text-d2_tablet lg:text-d2_desktop
    }
    .d3 {
        @apply text-d3_mobile md:text-d3_tablet lg:text-d3_desktop
    }
    .h1 {
        @apply text-h1_mobile md:text-h1_tablet lg:text-h1_desktop
    }
    .h2 {
        @apply text-h2_mobile md:text-h2_tablet lg:text-h2_desktop
    }
    .h3 {
        @apply text-h3_mobile md:text-h3_tablet lg:text-h3_desktop
    }
    .h4 {
        @apply text-h4_mobile md:text-h4_tablet lg:text-h4_desktop
    }
    .h5 {
        @apply text-h5_mobile md:text-h5_tablet lg:text-h5_desktop
    }
    .h6 {
        @apply text-h6_mobile md:text-h6_tablet lg:text-h6_desktop
    }

</style>